import React from 'react';
import { graphql } from 'gatsby';
import { motion as Motion } from 'framer-motion';

import { Main } from '../layouts/Main';
import { PageBuilder } from 'components/PageBuilder';
import { Footer } from 'components/Footer';

export interface ProjectTemplateProps {
    data: any;
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = ({ data }) => {
    const {
        slug,
        brand,
        darkTheme,
        headline,
        body,
        tasks,
        title,
        description,
        keywords,
        imageSocialShare
    } = data.project;

    const projectBody = body.map((item: any) => {
        if (item.type === 'stage') {
            item.brand = brand;
            item.headline = headline;
            item.tasks = tasks;
            item.darkTheme = darkTheme;
            return item;
        } else {
            return item;
        }
    });

    return (
        <Main
            seoProps={{
                siteUrl: 'https://everchanging.design',
                title,
                description,
                keywords,
                imageSocialShare,
                canonical: slug
            }}
        >
            <Motion.div
                animate={{
                    opacity: [0, 1],
                    y: [100, 0],
                    transition: { ease: 'easeInOut', duration: 0.75, delay: 0.4 }
                }}
                style={{ opacity: 0 }}
            >
                <PageBuilder body={projectBody} />
                <Footer />
            </Motion.div>
        </Main>
    );
};

export default ProjectTemplate;

export const data = graphql`
    query($pathSlug: String!) {
        project: projectsJson(slug: { eq: $pathSlug }) {
            slug
            darkTheme
            title
            brand
            description
            keywords
            imageSocialShare
            headline
            tasks
            body {
                link
                type
                alignment
                backgroundColor
                backgroundColors
                image
                images
                modifiers
                gridWrapper {
                    modifiers
                    spacingTop
                    spacingBottom
                }
                gridWrapperItems {
                    responsive
                }
                headline {
                    atom
                    content
                    modifiers
                    type
                }
                text {
                    atom
                    content
                    modifiers
                    spacingTop
                    spacingBottom
                    type
                }
            }
        }
    }
`;
